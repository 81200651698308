@import "styles/variables";

.component {
  @include border-radius($small-br);
  display: flex;
  border: rem-calc(2) solid $brand-darkest;
  padding: $padding-xs;
  min-width: rem-calc(85);
  justify-content: center;
  font: {
    weight: 700;
    size: $font-xs;
  }
  line-height: 1;
}