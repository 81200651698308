@import "styles/variables";

.component {
  position: relative; 
}

.empty {
  border-radius: $def-br;
  padding: $padding-md * 2;
  text-align: center;
  background-color: $white;
  border: $default-border;
  font-size: $font-sm1;
  color: $brand-middle-grey;
  width: 100%;
}