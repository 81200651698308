@import "styles/variables";

.root {
  position: relative;
  padding: {
    top: $padding-md;
    right: $padding-lg;
    left: $padding-lg;
    bottom: rem-calc(17);
  }
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
  border-bottom: $default-border;
  height: $finder-height;
  box-shadow: 0 0 rem-calc(10) rgba($brand-dark, 0.05);
}

.logo {
  display: flex;
  align-items: center;

  img {
    height: rem-calc(29);
  }
  :hover{
    cursor: pointer;
  }
}