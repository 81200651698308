@import "styles/variables";

.component {
  display: flex;
  align-items: center;

  :global(.vehicle-plate) {
    flex-shrink: 0;
  }
}

.content {
  display: flex;
  align-items: center;
  flex-grow: 1;
  overflow: hidden;
  padding-left: rem-calc(14);
}

.info {
  overflow: hidden;
  flex-grow: 1;
  padding-right: $padding-sm;
}

.name {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 1.2;
  color: $black;
  display: flex;
  align-items: center;
  font: {
    weight: 600;
    size: $font-sm2;
  }
}

.year {
  color: darken($white, 35%);
}

.vin {
  font-size: $font-xs;
  color: $brand-middle-grey;
  margin-top: rem-calc(4);
  line-height: 1.2;
}

.vehicle_model_name {
  margin-right: 8px;
}