@import "styles/variables";

$icon-size: rem-calc(70);

.root {
  display: flex;
  font-size: $font-sm1;
  padding: $padding-md;  
}

.icon {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: $default-border;
  width: $icon-size;

  svg {
    font-size: rem-calc(30);
  }
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 $padding-md;
  flex-grow: 1;
}

.name {
  margin-bottom: rem-calc(8);
  color: $black;
  font: {
    weight: 600;
    size: $font-sm2;
  }
}

.name,
.type,
.param {
  line-height: 1;
}

.type {
  display: flex;
  align-items: center;
  color: $brand-middle-grey;
}

.param {
  display: flex;
  align-items: center;
  color: $brand-dark;
  font-size: $font-sm;
}

.label,
.value {
  position: relative;
}

.label {
  margin-right: rem-calc(3);
}

@media (min-width: 0) and (max-width: $mobile-width) {
  .root {
    flex-wrap: wrap;

    .image {
      display: none;
    }

    .content,
    .type {
      width: 100%;
    }

    .content {
      padding: 0;
    }

    .type {
      padding: $padding-xs 0 0;
      line-height: 1;
    }
  }
}