@import "styles/variables";

$user-image-size: rem-calc(80);
$user-image-value-size: rem-calc(72);
$user-image-medium-size: rem-calc(60);
$user-image-value-medium-size: rem-calc(52);
$user-image-small-size: rem-calc(40);
$user-image-value-small-size: rem-calc(34);

.root {
  @include border-radius(50%);
  border: rem-calc(2) solid darken($app-bg, 8%);
  width: $user-image-size;
  height: $user-image-size;
  flex-shrink: 0;  
}

.value {
  @include border-radius(50%);
  width: $user-image-value-size;
  height: $user-image-value-size;
  background: {
    size: cover;
    position: center;
    repeat: no-repeat;
    color: $app-light-bg;
  }
  margin: rem-calc(2);
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    font-size: $font-xxl;
    color: darken($app-bg, 10%);
  }
}

.medium {
  width: $user-image-medium-size;
  height: $user-image-medium-size;
  border-width: rem-calc(2);

  .value {
    width: $user-image-value-medium-size;
    height: $user-image-value-medium-size;
    margin: rem-calc(2);
  }
}

.small {
  width: $user-image-small-size;
  height: $user-image-small-size;
  border-width: rem-calc(2);

  .value {
    width: $user-image-value-small-size;
    height: $user-image-value-small-size;
    margin: rem-calc(1);
  }

  svg {
    font-size: rem-calc(24);
  }
}

.has_story {
  border-color: lighten($danger, 10%);
}