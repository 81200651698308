@import "styles/variables";

.root {
  @media (max-width: $mobile-width) {
    &:global(.ui.modal) {
      > :global(.content) {
        padding: 0 !important;
      }
    }
  }
}

.actions {
  margin-top: $margin-lg;
  padding-top: $padding-lg;
  text-align: right;
  border-top: 1px solid $landing-grey-10;
}

.tariffs {
  .tariff {
    padding: 15px;
    background-color: #e9ecf0;
    border-radius: 5px;
    margin-bottom: 10px;
    cursor: pointer;
    color: $landing-grey;
    font-family: $openSans;

    &:hover {
      background-color: #d4dae1;
    }

    &:global(.active) {
      background-color: #bec7d2;
    }
  }
}

.top_row {
  display: flex;
  padding-bottom: 10px;
  border-bottom: 1px solid $landing-grey-10;
}

.select_icon_container {
  margin-right: 6px;
  width: 15px;
  height: 15px;
}

.select_circle {
  width: 12.5px;
  height: 12.5px;
  border-radius: 50%;
  border: 1px solid $landing-grey;
}

.duration {
  font-size: 10px;
  line-height: 1.4;
  font-weight: 700;
  text-transform: uppercase;
}

.bottom_row {
  display: flex;
  padding-top: 10px;
  justify-content: space-between;
}

.month_price {
  font-size: 30px;
  line-height: 32px;
  font-weight: 700;
  color: $landing-dark-grey;
}

.month_price_label {
  font-size: 12px;
  line-height: 20px;
  font-weight: 700;
  color: $landing-grey-70;
}

.price_block {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  .label {
    font-size: 10px;
    line-height: 1.4;
    font-weight: 600;
    color: $landing-grey-50;
    text-transform: uppercase;
  }

  .price_value {
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    color: $landing-dark-grey;
  }
}