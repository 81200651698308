@import "styles/variables";

.root {
  position: relative;
  letter-spacing: 0.25px;
  font: {
    family: $openSans;
    weight: 700;
    size: $font-sm;
  }

  &:global(.landing) {
    color: $landing-grey;
    font-family: $montserrat;
  }

  &:hover {
    cursor: pointer;

    .other {
      display: block;
    }
  }
}

.current {
  display: flex;
  align-items: center;
  line-height: 1;
  background-color: $landing-back-grey;
  padding: 4.5px 10px 4.5px 13px;
  border-radius: 6px;
}

.lang {
  width: 26px;
}

.icon_toggle {
  padding: 0 10px;

  @media (max-width: rem-calc(450)) {
    line-height: 0;
  }

  svg {
    font-size: 40px;

    @media (max-width: rem-calc(450)) {
      font-size: 28px;
    }
  }

}

.other {
  border-radius: $small-br;
  background-color: $white;
  box-shadow: $default-box-shadow;
  display: none;
  position: absolute;
  top: 33px;
  width: 100%;
  z-index: 9999;

  &:global(.by-icon) {
    top: 37px;
  }
}

.item {
  display: block;
  width: 100%;
  text-align: center;
  padding: $padding-sm $padding-md;
  transition: color ease-in-out 0.3s;
  color: $landing-grey;

  &:hover {
    color: $danger
  }
}