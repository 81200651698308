@import "styles/variables";

.component {
	min-height: 100vh;

	.banner{
		height:150px;
		background-image: url('/images/alfaBankBanners/alfa-2300x150.png');
		background-size: contain;
		background-repeat: no-repeat;
		
		width: calc(100vw - 300px);
	}
}

.banner:hover, .close:hover {
cursor: pointer;
}

.bunner_container{
	// top: -50px;
	position: fixed;
	flex-direction: column;
	display: flex;
	flex-shrink: 5;
	z-index: 50;
	left:300px;
	// bottom: 0;
}

@media(max-width: 1100px){
	.component {
		.banner{
			// background-image: url('/images/alfaBankBanners/alfa-1200x150.png');
			width: 100%;
			height: 150px;
		}
	}
}

@media(max-width: 980px){
	.component {
		.banner{
			background-image: url('/images/alfaBankBanners/alfa-1200x150.png');
			width: 100%;
			height: 150px;
		}
	}
}

@media(max-width: 580px){
	.component {
		.banner{
			background-image: url('/images/alfaBankBanners/alfa-600x150.png');
			width: 100%;
			height: 150px;
		}
	}
}