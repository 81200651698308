@import "styles/variables";

.layout {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	font-family: $openSans;

	:global(#ui-file-viewer),
	:global(#ui-pdf-viewer) {
		left: $side-menu-width;
	}

	@media (max-width: $tablet-width) {
		:global(#ui-file-viewer),
		:global(#ui-pdf-viewer) {
			left: 0 !important;
		}
	}
}

.top_panel {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	display: none;
	z-index: 9999;

	@media (max-width: $tablet-width) {
		display: block;
	}
}

.search {
	position: fixed;
	top: 0;
	right: 0;
	left: $side-menu-width;
	height: $finder-height;	
	z-index: 9998;

	@media (max-width: $tablet-width) {
		display: none;
		left: 0;
		top: $top-panel-height;
	}
}

.menu {	
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	width: $side-menu-width;
	display: flex;
	background-color: $app-light-bg;
	transition: left ease-in-out 0.3s;
	z-index: 9998;

	@media (max-width: $tablet-width) {
		left: -#{$side-menu-width};
		top: $top-panel-height;		
	}
}

.menu_overlay {
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	background-color: transparent;
	z-index: 9997;
}

.page {
	margin: {
		top: $finder-height;
		left: $side-menu-width;
	}
	position: relative;
	z-index: 1;
	flex-grow: 1;
	display: flex;
	flex-direction: column;

	> :global(div) {
		flex-grow: 1;
		width: 100%;
	}

	@media (min-width: $mobile-width) and (max-width: $tablet-width) {
		padding-top: $top-panel-height;
		margin: {
			left: 0;
			top: 0;
		}

		&.doublePadding {
			padding-top: $top-panel-height + $finder-height;
		}
	}

	@media (max-width: $mobile-width) {
		margin: {
			left: 0;
			top: 0;
		}
		padding-top: $top-panel-height;

		&.doublePadding {
			padding-top: $top-panel-height + $finder-height / 1.4;
		}
	}
}