@import "styles/variables";

.component {
  padding: $padding-sm $padding-sm rem-calc(7);

  .time {
    color: $brand-dark;
    line-height: 1;
    font: {
      size: rem-calc(10);
    };
  }

  .date {
    line-height: 1;
    font: {
      weight: 700;
      size: $font-sm;
    }    
  }
}