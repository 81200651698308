@import "styles/variables";

.component {
  flex-grow: 1;
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;  
}

.icon {
  margin-bottom: $margin-md;

  :global(svg) {
    font-size: rem-calc(100);
    color: $brand-pale-grey;
  }
}

.text {
  font-size: $font-sm;
}