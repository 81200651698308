.component{
    background-color: #16293F!important;
    color:white !important;
    padding:0!important;
    border: none !important;
    .content{
        background-color: #16293F!important;
        padding:0!important;
    }
}
.flex{
    display:flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-top:-15px;
    // margin-bottom: 10px;
}
.buttonstart{
    background-color: #16293F;
    width: 100%;
    color:white;
    padding: 15px;
line-height: 20px;
    border-radius:10px;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 600;
border:none;
align-self: center;

// margin-bottom:-15px;
}

.info{
    position:absolute;
    left:0;
    bottom:0;
}

.tour{
position:relative;
    background-color: #16293F;
    padding:18px;
    p{
        color:white;
    }
}

.buttons{
    margin-top:20px;
    display:flex;
    gap:30px;
    align-items: center;
    justify-content: flex-end;
}

.button{

    background-color: transparent;
    color:white;
    border:1px solid white;
    border-radius: 5px;
    // height: 35px;
    text-align: center;
    // width: 100px;
    padding:10px;
    text-transform: uppercase;
    font-weight: 600;
}

.second{
    background-color: white;
    color: #16293F;
    border:none;
    border-radius: 5px;
    // height: 35px;
    text-align: center;
    // width: 100px;
    padding:10px;
    font-weight: 600;
    text-transform: uppercase;
}
.second:hover{
    cursor: pointer;
    // background-color: wghite;
    opacity:0.66;
}
.button:hover{
    cursor: pointer;
    background-color: rgb(255,255,255, 0.13);
}
.buttonstart:hover{
    cursor: pointer;
    // background-color: rgb(255,255,255, 0.13);
    opacity:0.86;
}

@media (max-width: 900px) {
    .flex{
        display:none;
    }
}