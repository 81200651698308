@import "styles/variables";

$side-menu-nav-sections-width: rem-calc(90);
$side-menu-nav-sections-border: rem-calc(4);

@keyframes pulse {
  0% {
    border-right-color: $app-bg;
  }
  50% {
    border-right-color: $danger;
  }
  100% {
    border-right-color: $app-bg;
  }
}

.root {
  width: 100%;
  background-color: $white;
  overflow: hidden;
  border-right: rem-calc(4) solid $app-bg;
  flex-direction: column;
  display: flex;

  [class^="side-menu-section_root"]:first-child {
    border-top: none;
  }

  &.danger {
    animation: pulse 3s ease-in-out infinite;
  }
}

.scroll {
  flex-grow: 1;
  overflow-x: hidden;
  overflow-y: scroll;
  margin-right: -#{$margin-lg};
}

.scroll_content {
  position: relative;
  width: $side-menu-width;  
  display: flex;
  flex-direction: column;
  z-index: 2;
}

.root,
.scroll_wrapper,
.scroll {
  display: flex;
}

.border {
  border-right: $side-menu-nav-sections-border solid $app-bg;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
}

.nav {
  flex-grow: 1;
  padding: 0 $padding-lg + $side-menu-nav-sections-border 0 $padding-lg;
}

.permanent {
  padding: $padding-md 0;
  // padding-bottom: 15px;
}