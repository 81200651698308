@import "styles/variables";

.component {
  padding: $padding-sm 0 0 $margin-xs;
  background-color: darken($app-light-bg, 1%);
  display: flex;
  border-left: rem-calc(4) solid transparent;

  .param {
    padding: 0 $padding-sm $padding-sm 0;
    line-height: 1;

    :global(.ui.dropdown) {
      padding: rem-calc(1) $padding-xs rem-calc(4);
      border-radius: $small-br;
      transition: background-color ease-in-out 0.3s;

      &:hover {
        background-color: darken($app-bg, 2%);
      }
    }

    :global(.ui.dropdown > .text) {
      text-transform: uppercase;
      font: {
        weight: 700;
        size: $font-xs;
      }
    }

    :global(.ui.dropdown > .menu) {
      max-height: rem-calc(200);
      overflow: auto;
    }

    :global(.ui.dropdown > .dropdown.icon) {
      margin-left: $margin-xs;
      color: $brand-middle-grey;
    }
  }

  .param_label {
    text-transform: uppercase;
    margin-bottom: rem-calc(4);
    padding: 0 $padding-xs;
    color: $brand-middle-grey;
    font: {
      weight: 700;
      size: rem-calc(8);
    }
  }

  .id {
    width: rem-calc(47);
    text-align: center;
    padding-right: $padding-xs;
    font: {
      weight: 600;
      size: $font-xs2;
    }

    .param_label {
      margin-bottom: rem-calc(7);
    }
  }

  .status {
    flex-grow: 1;
  }

  .performer {
    .param_label {
      text-align: right;
    }
  }

  &.preorder {
    border-color: $brand-middle-grey;
  }
  
  &.canceled {
    border-color: $danger;
  }
  
  &.inWork {
    border-color: $info;
  }
  
  &.reconciliation {
    border-color: $brand;
  }
  
  &.completed {
    border-color: $teal;
  }
  
  &.closed {
    border-color: $teal;
  }
  
  &.suspended {
    border-color: $brand;
  }
}

.default_cursor {
  cursor: default !important;

  > :global(.text) {
    text-decoration: none !important;
  }
}