@import "styles/variables";

.container{
    display: flex;
    gap:5px;
    position: relative;
    align-items: center;
    // color:rgb(128, 128, 128);
    color:#16293F;
    font-weight: 550;
    overflow: visible !important;
    p{
        color:black;
        opacity: 0.66;
 
    }
}

.popover{
    position: fixed;
    left: 5;
    top: 5;
    line-height: 18px;
    overflow: visible !important;
    z-index: 9999;
    display: flex;
    gap: 15px;
    // background-color: $gray;
    background-color: #16293F;
    // color: rgb(105, 105, 105);
    color:white;
    padding: 15px;
    border-radius: 20px;
    flex-direction: column;
    width: 400px;
    font-weight: 550;
}

.button{
    align-self: flex-end;
    border-radius: 15px;
    width: 150px;
}

@media (max-width: $mobile-width) {
    .popover {
        width: 300px;
      left:10px;
    }
  }