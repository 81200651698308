@import "styles/variables";

$user-story-large-size: rem-calc(80);
$user-story-medium-size: rem-calc(60);
$user-story-small-size: rem-calc(40);

.root {
  position: relative;
  
  &.large {
    width: $user-story-large-size;
  }

  &.medium {
    width: $user-story-medium-size;
  }

  &.small {
    width: $user-story-small-size;
  }  
}

.name {
  line-height: 1;
  margin-top: rem-calc(7);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  font: {
    size: $font-xs2;
  }
}