@import "styles/variables";

$border-size: rem-calc(4);
$border: $border-size solid $app-bg;

.component {
  z-index: 9999;
  display: flex;
  background-color: $white;
  height: $top-panel-height;
  justify-content: space-between;  
}



.close{
width: 10px;
height: 10px;
}


.left,
.center,
.right {
  display: flex;
}

.left,
.right {
  min-width: $top-panel-height;
}
  
.center {
  flex-grow: 1;
  align-items: center;
  justify-content: center;
}

.title {
  text-transform: uppercase;
  flex-grow: 1;
  text-align: center;
  color: $brand-dark;
  font: {
    weight: 700;
    size: $font-md;
  }
  display: flex;
  justify-content: center;
  align-items: center;
}

.menu_button {
  width: $top-panel-height;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 rem-calc(14);
  
  &:hover {
    cursor: pointer;

    .line {
      background-color: $danger;
    }
  }

  .line {
    border-radius: $def-br;
    height: rem-calc(3);
    background-color: $brand-dark;
    transition: background-color ease-in-out 0.3s;

    &:first-child {
      width: rem-calc(13);
      margin-bottom: $margin-xs;
    }

    &:last-child {
      width: rem-calc(20);
    }
  }
}

.notifications,
.search {
  width: $top-panel-height;
  height: $top-panel-height;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    color: $brand-dark;
    transition: color ease-in-out 0.3s;
  }

  &:hover {
    cursor: pointer;
    
    svg {
      color: $danger;
    }
  }
}