@import "styles/variables";

$side-menu-leaf-height: rem-calc(48);

.root {
	display: flex;
	align-items: center;
	position: relative;
	&:hover{
		.star{
			display: block;
		}
	}
}

.link {
	border-radius: $small-br;
	display: flex;
	align-items: center;
	flex-grow: 1;
	color: $brand-darkest;
	transition: background-color ease-in-out 0.3s; //, color ease-in-out 0.3s
	padding: $padding-sm $padding-md;	

	&:last-child {
		margin-bottom: 0;
	}

	> svg {
		font-size: rem-calc(24);
		margin-right: $margin-md;
		color: lighten($brand-dark, 15%);
		transition: color ease-in-out 0.3s;
	}

	&:hover{
		background-color: $app-light-bg;
		
		color: $default-text-color;
		
		> svg {
			color: $danger;
		}
		&:after{
			display: none;
		}
	}
}
.active{
	background-color:rgba(39, 69, 104, 0.1);
	color:rgba(39, 69, 104);
}
.text {
	flex-grow: 1;
	line-height: 1;
	font: {
		size: $font-sm1;
	}
}

.action {
	margin-left: $margin-md;
}

.new {
	padding: 4px;
	background: #ff4067;
	font-style: normal;
	font-weight: 700;
	font-size: 8px;
	line-height: 11px;
	/* identical to box height */

	text-transform: uppercase;
	border-radius: 2px;
	color: #FFFFFF;
}

.star{
color:gray;
position: absolute;
top:10px;
right:5px;
width: 24px;
height: 24px;
background-image: url('/public/images/star_border.png');
background-position: center;
background-repeat: no-repeat;
  background-size: contain;
  display: none;
}
.star:hover{
	cursor: pointer;
}