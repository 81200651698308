@import "styles/variables";

$vehicle-logo-size: rem-calc(32);

.component {
  @include border-radius(50%);
  height: $vehicle-logo-size;
  width: $vehicle-logo-size;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  flex-shrink: 0;

  .icon {
    margin-right: 0;
  }

  :global(img) {
    width: 100%;
  }
}