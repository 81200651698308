@import "styles/variables";

.root {  
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  border-top: $default-border;
  box-shadow: 0 0 rem-calc(10) rgba($brand-dark, 0.05);
}

.data {
  flex-grow: 1;
  padding: $padding-lg;
  overflow: hidden;
  &:hover {
    cursor: pointer;
    background-color: $app-light-bg;
  }
}

.name {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: $black;
  font: {
    weight: 600;
    size: $font-sm1;
  }
}

.garage {
  line-height: 1.4;
  font-size: $font-xs2;
  color: $brand-middle-grey;
  margin-top: rem-calc(5);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.logout {
  text-align: center;
  border-left: $dotted-border;
  padding: $padding-lg;
  transition: background-color ease-in-out 0.3s;

  &:hover {
    cursor: pointer;
    background-color: $app-light-bg;
  }

  &:active {
    background-color: $app-bg;
  }

  .logout_icon {
    svg {
      font-size: rem-calc(18);
    }
  }

  .logout_text {
    color: $brand-middle-grey;
    font-size: $font-xs2;
    margin-top: rem-calc(2);
  }
}