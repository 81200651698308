@import "styles/variables";

.root {
  display: flex;
}

.link {
  flex-grow: 1;
  flex-shrink: 0;
  color: $default-text-color;
  display: flex;
  align-items: center;

  &:hover {
    color: $link-color;

    [class^="vehicle-simple_name"] {
      color: $link-color;
    }
  }

  [class^="vehicle-simple_component"] {
    width: 100%;
  }
}