@import "styles/variables";

.root {
  display: flex;
  align-items: center;
  padding: $padding-md;

  > a {
    flex-grow: 1;
  }
}

.info {
  display: flex;
  align-items: center;
  flex-grow: 1;
}

.image {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.name {
  flex-grow: 1;
  color: $black;
  font: {
    weight: 600;
    size: $font-sm2;
  }
}

.role {
  color: $brand-middle-grey;
}

.data {
  flex-grow: 1;
  padding-left: $padding-md;
  line-height: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.phone {
  margin-top: rem-calc(7);
  color: $brand-dark;
  font-size: $font-sm;

  :global(a) {
    color: $brand-dark;
  }
}

.phone_button,
.edit_button {
  position: relative;
  padding-left: $padding-md;
}