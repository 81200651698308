@import "styles/variables";

.root {

  .image {
    width: 100%;
    object-fit: contain;
  }

  .text {
    font-size: $font-md1;
    line-height: 1.7;
    text-align: center;
  }

  .modal_title {
    font-weight: 700;
    font-size: rem-calc(21);
    margin-top: $margin-lg;
    margin-bottom: $margin-md;
    text-align: center;
  }

  .button_container {
    display: flex;
    justify-content: center;
  }

}

