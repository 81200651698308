@import "styles/variables";

.root {
  border-radius: $def-br !important;
  position: absolute;
  top: 0;
  right: rem-calc(16);
  color: $danger;
  text-transform: uppercase;
  line-height: 1;
  padding: rem-calc(14) 0 rem-calc(12);
  text-align: right;
  display: flex;
  align-items: center;
  line-height: 1;
  font: {
    weight: 700;
    size: $font-md;
  }

  &:hover {
    cursor: pointer;
  }

  svg {
    font-size: $font-xxl;
  }
}