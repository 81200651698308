.shepherd-footer{
  display: flex;
  justify-content: space-between;
}

.button{
  color:white;
  background-color: transparent!important;
  border:1px solid gray!important;
  border-radius: 5px!important;
  padding: 10px;
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
}

.button_next{
  color:white;
  background-color: transparent!important;
  border:1px solid gray!important;
  border-radius: 5px!important;
  font-size: 12px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  height: 35px;
  width: 35px;
}


.frame{
  background-color: #16293F!important;
  color:white;
  z-index: 9999!important;
  font-family: 'Open Sans'!important;
  width: 750px!important;


}
.button_end{
  color:white;
  background-color: transparent!important;
  border:1px solid gray!important;
  border-radius: 5px!important;
  font-size: 12px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  height: 35px;
  width: 100%;
	align-self: center;
  margin:auto;
  text-transform: uppercase;
  margin-top: 20px;
  }

  .button_end:hover, .button:hover, .button_next:hover{
cursor: pointer;
  }
.shepherd-footer {
  align-items: center;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  display: flex;
  justify-content: space-between;
  padding: 0 .75rem .75rem;

  .shepherd-button:last-child {
    margin-right: 0;
  }

  .progress {
    font-size: .8rem;
    width: 100%;
  margin-top: 20px;
  height: 15px;
  border:1px solid gray;
  border-radius: 5px;
  }
}

.bar{
// background-color: gray;

width: 100%;
margin-top: 20px;
height: 5px;
border:1px solid gray;
border-radius: 5px;
}

.progress_text{
margin-top:10px;
}

.shepherd-text p{
  margin-bottom: 15px!important;

}


