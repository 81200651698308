@import "styles/variables";

.component {
  margin-top: $margin-md * 2;
  display: flex;
  flex-direction: column;

  &:last-child {
    margin-bottom: 0;
  }
}

.content {
  @include border-radius($def-br);
  background-color: $white;
  margin-right: $margin-md * 2;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  border: $default-border;
  color: $default-text-color;
  transition: box-shadow 0.3s ease-in-out;

  &:hover {    
    box-shadow: $default-box-shadow;
    color: $default-text-color;
  }

  :global(a) {
    color: $default-text-color;
  }
}

.vehicle_wrapper {
  padding: $padding-md;
}

.description {
  padding: $padding-md;
  font-size: $font-sm1;
  flex-grow: 1;
}

.footer {
  border-top: $default-border;
  margin: 0 $margin-md;
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: 1;

  .date {
    color: $brand-middle-grey;
    font: {
      weight: 600;
      size: $font-xs2;
    }
  }

  .total {
    font: {
      weight: 700;
      size: $font-md;
    }
  }

  .date,
  .total {
    padding: $padding-md 0;
  }
}

@media (min-width: 0) and (max-width: $mobile-width) {
  .component {
    margin-top: $margin-md;
  }

  .content {
    margin-right: $margin-md;
  }
}