@import "styles/variables";

.root {
  display: flex;
}

.link {
  flex-grow: 1;
  display: flex;
  align-items: center;

  [class^="counterparty-item_root"] {
    width: 100%;
    padding: 0;
  }

  &:hover {
    [class^="counterparty-item_name"],
    [class^="counterparty-item_param"] {
      color: $link-color;
    }
  }
}