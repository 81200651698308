@import "styles/variables";

.component {
  background-color: $white;
  display: flex;
  align-items: center;
  min-height: rem-calc(60);
}

.date,
.value {
  padding: $padding-md;
}

.date {
  position: relative;

  :global([class*="ui-date_component"]) {
    padding: 0;
  }
}

.value {
  border-left: $default-border;  
}