@import "styles/variables";

$btn-large-size: rem-calc(60);
$btn-medium-size: rem-calc(40);
$btn-small-size: rem-calc(30);
$icon-container-size: rem-calc(40);

.component {
  position: relative;
  display: inline-flex;  

  &.large {
    .button {
      width: $btn-large-size;
      height: $btn-large-size;
  
      :global(svg) {
        font-size: $font-xxl;
      }
    }

    .menu {
      top: $btn-large-size + $margin-xs;
    }
  }

  &.medium {
    .button {
      width: $btn-medium-size;
      height: $btn-medium-size;

      &:global(.with-text) {
        width: auto;
      }
  
      :global(svg) {
        font-size: $font-xl;
      }
    }

    .menu {
      top: $btn-medium-size + $margin-xs;
    }
  }

  &.small {
    .button {
      width: $btn-small-size;
      height: $btn-small-size;
  
      :global(svg) {
        font-size: $font-lg;
      }
    }

    .menu {
      top: $btn-small-size + $margin-xs;
    }
  }
}
.recm{
  position:absolute;
  top:20px;
  left: 20px;
width: 1px;
}
.button {
  @include border-radius(50%);
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $app-bg;
  border: none;
  cursor: pointer;
  color: $default-text-color;
  outline: none !important;
  transition: background-color ease-in-out 0.3s, color ease-in-out 0.3s;

  &[disabled] {
    @extend .disabled;
  }

  &:hover {
    background-color: darken($app-bg, 5%);
  }

  &:active {
    background-color: darken($app-bg, 7%);
  }

  &.inverted {
    color: $white;
  }

  &.positive:not(.inverted) {
    background-color: $teal;
    color: $white;

    &:hover {
      background-color: darken($teal, 3%);
    }
  
    &:active {
      background-color: darken($teal, 5%);
    }
  }

  &.negative:not(.inverted) {
    background-color: $danger;
    color: $white;

    &:hover {
      background-color: darken($danger, 3%);
    }
  
    &:active {
      background-color: darken($danger, 5%);
    }    
  }

  &.positive.inverted {
    color: $teal;
  }

  &.negative.inverted {
    color: $danger;
  }

  &.transparent {
    background-color: transparent;
  }

  &:global(.with-text) {
    padding: 0;
    border-radius: 20px;
    background-color: $app-bg!important;

    &:hover {
      background-color: darken($app-bg, 3%)!important;

      .button_icon {
        background-color: darken($teal, 3%);
      }
    }
  }

  .button_icon {
    @include border-radius(50%);
    background-color: $teal;
    color: $white;
    border: rem-calc(3) solid $app-bg;
    width: $icon-container-size;
    height: $icon-container-size;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    transition: background-color ease-in-out 0.3s;

    :global(svg) {
      line-height: 1;
    }
  }

  .button_text {
    color: $default-text-color;
    line-height: 1;
    padding: {
      left: $padding-sm;
      right: $padding-md;
    }
    font: {
      weight: 600;
      size: $font-sm;
    }

    &:empty {
      padding: 0;
    }
  }
}

.disabled {
  opacity: 0.5;
  cursor: default !important;
}

.menu {
  @include border-radius($def-br);
  position: absolute;
  background-color: $white;
  box-shadow: $default-box-shadow;
  overflow: hidden;
  z-index: 9999;

  .negative {
    color: $danger;
  }

  &.menu_left {
    right: 0;
  }

  &.menu_right {
    left: 0;
  }

  .menu_item {
    display: flex;
    align-items: center;
    padding: $padding-md $padding-lg $padding-md $padding-md;
    white-space: nowrap;
    font-size: $font-sm1;

    &:not(:last-child) {
      border-bottom: rem-calc(1) solid $app-light-bg;
    }

    &:hover {
      cursor: pointer;
      background-color: $app-light-bg;

      &.disabled {
        background-color: transparent;
      }
    }
  }

  .menu_item_icon {
    display: flex;
    align-items: center;

    svg {
      margin-right: $margin-md;
    }
  }

  .menu_item_text {
    
  }
}

@media (max-width: 560px) {
  .menu {
    width: 270px;
    .menu_item {
      white-space: normal;
    }
  }
}