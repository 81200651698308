@import "styles/variables";

.root {
  position: relative;

  @media (max-width: $tablet-width) {
    border-top: $dotted-border;
  }
}



.close{
  position: absolute;
  top:0;
  left:0;
  pointer-events: auto;
  color:white;
}
.bunner_container{
position: relative;
}




.overlay {
  position: fixed;
  top: $finder-height;
  left: $side-menu-width;
  right: 0;
  bottom: 0;
  background-color: rgba($brand-darkest, 0.7);
  z-index: 1;
}

.input_wrapper {
  position: relative;
  display: flex;
  background-color: $white;
  height: $finder-height;
  border-bottom: $default-border;
  padding: 0 $padding-md 0 $padding-md * 2;
  box-shadow: 0 0 rem-calc(10) rgba($brand-dark, 0.05);
  z-index: 3;

  @media (max-width: $mobile-width) {
    padding: 0 0 0 rem-calc(12);
  }
}

.selector,
.icon,
.clear {
  display: flex;
  align-items: center;
}

.icon {
  svg {
    font-size: $font-xxl;
    color: $brand-middle-grey;
  }
}

.clear {
  padding-bottom: rem-calc(2);

  @media (max-width: $mobile-width) {
    margin-right: -#{rem-calc(4)};
  }
}

.input {
  flex-grow: 1;
  display: flex;

  :global(.ui.input) {
    width: 100%;

    > input {
      border-radius: 0;
      border: none;
      line-height: 1;
      padding: $padding-sm $padding-md rem-calc(13);
    }
  }
}

.results {
  position: fixed;
  top: $finder-height;
  left: $side-menu-width;
  right: 0;
  max-height: calc(100vh - #{$finder-height});
  overflow-x: hidden;
  overflow-y: auto;
  background-color: $white;
  box-shadow: $default-box-shadow;
  z-index: 2;
}

.results_header {
  background-color: $app-light-bg;
}

.results_title {
  text-transform: uppercase;
  text-align: center;
  color: $brand-dark;
  line-height: 1;
  padding: $padding-md * 2 $padding-md * 2 0;
  
  font: {
    size: $font-lg;
    weight: 700;
  }

  @media (max-width: $mobile-width) {
    display: none;
  }
}

.filters {
  padding: $padding-md * 2 $padding-md * 2 0;
  display: flex;
  justify-content: center;

  :global(.ui.button.teal) {
    background-color: $teal !important;
  }

  @media (max-width: $mobile-width) {
    display: none;
  }
}

.filters_resource {
  border-radius: $small-br $small-br 0 0;
  padding: $padding-md;
  transition: background-color ease-in-out 0.3s;
  line-height: 1;
  border: rem-calc(1) solid $app-light-bg;
  font: {
    size: $font-sm1;
  }

  &:hover {
    cursor: pointer;
    background-color: $app-bg;
  }

  &.active {
    font-weight: 600;
    color: $black;
    background-color: $white;
    border: {
      top: $default-border;
      left: $default-border;
      right: $default-border;
      bottom: rem-calc(1) solid $white;
    }
  }
}

.results_content {
  padding: $padding-md * 2;

  @media (max-width: $mobile-width) {
    padding: $padding-md * 2 $padding-md;
  }
}

.resource {
  &:not(:last-child) {
    margin-bottom: $margin-md * 6;
  }

  &.repairs {
    .resource_title {
      margin-bottom: 0;
    }
  }
}

.resource_title {
  line-height: 1;
  text-transform: uppercase;
  padding-bottom: $padding-md;
  border-bottom: $dotted-border;
  color: $danger;
  font: {
    weight: 700;
    size: $font-sm;
  }

  @media (max-width: $mobile-width) {
    text-align: center;
  }
}

.resource_result {
  &.repairs {
    display: flex;
    flex-wrap: wrap;
    margin-right: -#{$margin-md * 2};

    @media (max-width: $mobile-width) {
      margin-right: -#{$margin-md};
    }
  }
}

.resource_title,
.filters,
.results_content,
.empty_results {
  max-width: $default-view-width;
  margin: auto;
}

.empty_results {
  padding: $padding-md * 2;
  text-align: center;

  @media (max-width: $mobile-width) {
    padding: $padding-md * 2 $padding-md;
  }
}

.entity_item {
  &:hover {
    cursor: pointer;

    .actions {
      padding: $padding-md 0 $padding-md $padding-md;
      width: auto;
      margin-left: $margin-md;
      border-left: $dotted-border;
    }
  }

  &.vehicles,
  &.clients,
  &.counterparties {
    border-bottom: $dotted-border;
  }

  &.repairs {
    display: flex;
    flex-grow: 1;
    flex-basis: 33.3333%;
    min-width: rem-calc(370);

    @media (max-width: $mobile-width) {
      min-width: auto;
      width: 100%;
    }
  }

  .actions {
    padding: $padding-md 0;
    width: 0;
    overflow: hidden;
  }
}

@media (max-width: $tablet-width) {
  .results {
    top: $finder-height + $top-panel-height;
    max-height: calc(100vh - #{$finder-height} - #{$top-panel-height});
  }

  .overlay,
  .results {
    left: 0;
  }
}