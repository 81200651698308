@import "styles/variables";

.root {
  position: relative;

  &:not(:first-child) {
    border-top: $default-border;
  }
}

.header {
  padding: $padding-lg 0;
  display: flex;
  align-items: center;
  line-height: 1;
  max-height: rem-calc(66);

  &.expanded {
    padding-bottom: $padding-sm;
  }

  &:hover {
    cursor: pointer;
  }
}

.arrow {
  position: relative;
  color: $brand-middle-grey;
  text-align: right;
  margin-left: -#{rem-calc(7)};
  margin: {
    right: $margin-xs;
  }
  height: rem-calc(24);
}

.label {
  flex-grow: 1;
  color: $black;
  font: {
    size: $font-md;
    weight: 600;
  }
}

.action {
  padding-left: $padding-md;
}

.content {
  position: relative;
  padding-bottom: $padding-md;
}