@import "styles/variables";

.root {
  position: relative;
  border-radius: $small-br;
  padding: rem-calc(12);
  margin: $margin-lg 0 0;
  background: $app-bg;
  background: linear-gradient(340deg, darken($app-light-bg, 5%) 0%, $app-light-bg 100%);
}


.content {
  position: relative;
  z-index: 2;

  .title {
    margin-bottom: $margin-md;
  }

  .title,
  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    line-height: 1;
  }

  .text {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-transform: uppercase;
    font: {
      weight: 600;
      size: $font-xs1;
    }
  }

  .value {
    font: {
      weight: 700;
      size: $font-lg;
    }
  }

  .actions,
  .bottom {
    text-transform: uppercase;
    font: {
      weight: 600;
      size: $font-xs1;
    }
  }

  .bottom,
  .text,
  .actions {
    a:hover {
      text-decoration: underline;
    }
  }

  .bottom {
    margin-top: $margin-sm;
  }
}

.alert {
  margin-top: 15px;
  padding: $padding-sm;
  border: 1px solid $landing-grey-30;
  border-radius: 10px;
  background-color: $landing-grey-10;
  font-family: 'OpenSans', sans-serif;
  margin-bottom: 25px;

  .title {
    font-size: 10px;
    line-height: 1.4;
    text-transform: uppercase;
    color: $landing-grey;
    font-weight: 600;
    margin-bottom: 5px;
  }

  .text {
    font-size: 11px;
    line-height: 1.4;
    color: $landing-grey;
    font-weight: 400;
    margin-bottom: 10px;
  }

  &:global(.danger) {
    border-color: $red-30;
    background-color: $red-15;

    .title {
      color: $dark-red;
    }

    .text {
      color: $dark-red;
    }
  }

  &:global(.warning) {
    border-color: $orange-20;
    background-color: $orange-10;

    .title {
      color: $orange-text;
    }

    .text {
      color: $orange-text;
    }
  }

  .actions {
    display: flex;
    justify-content: flex-end;
    >button {
      flex-basis: 50%;
    }
  }
}