@import "styles/variables";

.root {
  display: flex;

  [class^="user-item_root"] {
    flex-grow: 1;
    padding: 0;
  }

  &:hover {
    [class^="user-item_name"],
    [class^="user-item_phone"] {
      color: $link-color;
    }
  }
}